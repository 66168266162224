import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import { useStaticQuery, graphql } from 'gatsby';

import {
  isNil,
  map,
  reverse,
  reduce,
  subtract,
  prepend,
  propEq,
  sortBy,
  find,
  filter,
  prop,
} from 'ramda';

import theme from '@greenberry/salal/theme';
import Heading from '@greenberry/salal/components/Heading';
import Section from '@greenberry/salal/components/Section';
import Strong from '@greenberry/salal/components/Strong';
import Introduction from '@greenberry/salal/components/Introduction';
import ImageInfoBlock from '@greenberry/salal/components/ImageInfoBlock';
import InteractiveMap from '@greenberry/salal/components/InteractiveMap';
import Formula from '@greenberry/salal/components/Charts/Formula';
import ComboChart from '@greenberry/salal/components/Charts/ComboChart';
import DonutChart from '@greenberry/salal/components/Charts/Donut';
import Text from '@greenberry/salal/components/Text';
import Legend from '@greenberry/salal/components/Legend';
import formatValue from '@greenberry/salal/utils/formatValue';
import LineChart from '@greenberry/salal/components/Charts/Line';
import PredictionLineChart from '@greenberry/salal/components/Charts/PredictionLine';

import notNil from '../../../utils/notNil';
import formatDataSource from '../../../utils/formatDataSource';
import parseTooltip from '../../../utils/parseTooltip';
import formatEqualization from '../../../utils/formatEqualization';
import isMobile from '../../../utils/isMobile';

import equalizationColorMap from '../../../constants/equalizationColorMap';
import useMapJson from '../../../hooks/useMapJson';

import PartnershipLayout from '../../../components/PartnershipLayout';
import Loading from '../../../components/Loading';
import ThemeFooter from '../../../components/ThemeFooter';
import ChartWrapper from '../../../components/ChartWrapper';

import {
  formatSupportBudget,
  formatReservePosition,
  formatCalculatedResistance,
  formatEquity,
  formatPrognosisData,
  getMaterialFixedAssets,
  getEquity,
  getExcessiveReserve,
  getSignalValue,
  getNetGain,
  getPublicEquity,
  getGovermentGrant,
} from './utils';

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const GraphHeading = styled(Heading)`
  max-width: 180px;
  text-align: center;
  margin-top: ${theme.spacing('l')};
`;

const IntroText = styled(Text)`
  margin-top: ${theme.spacing('s')};
`;

const chartOptions = {
  Chart: LineChart,
  xKey: 'label',
  unit: 'euro',
  width: 600,
  height: 600,
};

const indicators = [
  {
    name: 'supportBudget',
    ...chartOptions,
    sideNavData: {
      name: 'Budget',
    },
  },
  {
    name: 'reservePosition',
    ...chartOptions,
    sideNavData: {
      name: 'Reservepositie',
    },
  },
  {
    name: 'eigen vermogen',
    ...chartOptions,
    sideNavData: {
      name: 'Eigen vermogen',
    },
  },
  {
    name: 'signaleringswaarde',
    sideNavData: {
      name: 'Signaleringswaarde',
    },
  },
  {
    name: 'vereveningen',
    sideNavData: {
      name: 'Vereveningen',
    },
  },
];

const Financial = ({
  pageContext: { dato, partnership, copy, sector, ...pageContext },
}) => {
  const _partnership = Object.assign({}, dato, partnership);
  const prognosisData = formatPrognosisData(dato.prognoses);

  const { infoBlockTitle, infoBlockBody, heading, lead, intro } = copy;
  const [currentBaten, setCurrentBaten] = useState();
  const [currentLasten, setCurrentLasten] = useState();
  const [selectedViews, setSelectedViews] = useState(
    reduce(
      acc => ({
        [indicators[0].name]: 'overview',
        [indicators[1].name]: 'overview',
        [indicators[2].name]: 'overview',
        [indicators[3].name]: 'overview',
        [indicators[4].name]: 'overview',
        ...acc,
      }),
      {},
      indicators
    )
  );

  const setSelectedView = (indicator, view) => () => {
    const clone = Object.assign({}, selectedViews);
    clone[indicator] = view;
    setSelectedViews(clone);
  };

  const { infoImage, partnerships, ...dataSources } = useStaticQuery(
    graphql`
      query getFinancial {
        reservePosition: datoCmsDataSource(
          indicator: { eq: "Reservepositie" }
        ) {
          source
          date
        }
        equity: datoCmsDataSource(indicator: { eq: "Eigen vermogen" }) {
          source
          date
        }
        equalizationSource: datoCmsDataSource(
          indicator: { eq: "Vereveningen" }
        ) {
          source
          date
        }
        signalingValue: datoCmsDataSource(
          indicator: { eq: "Signaleringswaarden" }
        ) {
          source
          date
        }
        supportBudget: datoCmsDataSource(
          indicator: { eq: "Ondersteuningsbudget" }
        ) {
          source
          date
        }
        infoImage: file(relativePath: { eq: "financien.png" }) {
          childImageSharp {
            fluid(maxWidth: 700, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        # We should've filtered the partnerships here but Gatsby does not allow that.
        # https://www.gatsbyjs.org/docs/use-static-query/#known-limitations
        partnerships: allPartnership {
          edges {
            node {
              externalId
              name
              sector

              indicators {
                signalingValue {
                  _2020 {
                    publicEquity
                    signalValue
                    netGain
                    excessiveReservePercent
                    excessiveReserve
                  }
                }
                equalization {
                  _2015_2020 {
                    equalizationRate
                    equalizationCategory
                    amountPerStudent
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const formatLegendTranslations = {
    personelCharges: {
      label: 'Personeelslasten',
      color: theme.color('chart.7'),
    },
    depreciations: {
      label: 'Afschrijvingen',
      color: theme.color('chart.2'),
    },
    housingCharges: {
      label: 'Huisvestigingslasten',
      color: theme.color('chart.3'),
    },
    otherCharges: {
      label: 'Overige lasten',
      color: theme.color('chart.4'),
    },
    throughPayments: {
      label: 'Doorbetalingen aan schoolbesturen',
      color: theme.color('chart.5'),
    },
    subsidies: {
      label: 'Subsidies',
      color: theme.color('chart.6'),
    },
    governmentGrants: {
      label: 'Rijksbijdragen',
      color: theme.color('chart.1'),
    },
    otherGovernmentGrants: {
      label: 'Overige Overheidsbijdragen',
      color: theme.color('chart.8'),
    },
    tutionFeeCharges: {
      label: 'Collegegeld lasten',
      color: theme.color('chart.9'),
    },
    thirdPartyBenefits: {
      label: 'Baten in opdracht van derden',
      color: theme.color('chart.10'),
    },
    otherBenefits: {
      label: 'Overige baten',
      color: theme.color('chart.12'),
    },
  };

  const formatLegendItems = (formattedIndicator = []) =>
    reverse(sortBy(prop('value'), formattedIndicator)).map(({ label }) => ({
      label: formatLegendTranslations[label].label,
      color: formatLegendTranslations[label].color,
    }));

  const formatIndicator = indicator => {
    const keys = Object.keys(indicator);
    reduce((acc, key) => indicator[key] + acc, 0, keys);
    const data = map(
      key => ({
        label: key,
        name: key,
        unit: 'EUR',
        value: indicator[key],
      }),
      keys
    );

    return data;
  };

  const mapJson = useMapJson(sector === 'primair-onderwijs');

  const introductionOptions = {
    name: 'Introductie',
    id: 'introductie',
  };

  const sideNavOptions = [
    {
      name: 'Budget',
      id: 'budget',
    },
    {
      name: 'Eigen vermogen',
      id: 'eigen-vermogen',
    },
    {
      name: 'Reservepositie',
      id: 'reservepositie-reserveposition',
    },
    {
      name: 'Signaleringswaarde',
      id: 'signaleringswaarde',
    },
    {
      name: 'Vereveningen',
      id: 'vereveningen',
    },
  ];

  const [selectedIndicator, selectIndicator] = useState(
    introductionOptions.name
  );

  const equalization = formatEqualization(partnerships, sector);
  const governmentGrants = getGovermentGrant(_partnership);
  const calculatedEquity = subtract(
    getEquity(_partnership),
    getMaterialFixedAssets(_partnership)
  );

  const supportBudget = formatIndicator(
    partnership.indicators.supportBudget['_2020']
  );

  const removedEmptyValuesSupportBudget = filter(
    data => data.value !== 0,
    supportBudget
  );

  const totalBenefits = formatValue(
    find(propEq('label', 'totalBenefits'))(supportBudget)['value'],
    'EUR'
  );

  const totalCharges = formatValue(
    find(propEq('label', 'totalCharges'))(supportBudget)['value'],
    'EUR'
  );

  const totalDifference = formatValue(
    find(propEq('label', 'totalDifference'))(supportBudget)['value'],
    'EUR'
  );

  const supportBudgetCharges = filter(
    data =>
      [
        'personelCharges',
        'depreciations',
        'housingCharges',
        'otherCharges',
        'throughPayments',
        'subsidies',
      ].includes(data.label),
    removedEmptyValuesSupportBudget
  );

  const colorFormat = indicator =>
    sortBy(prop('value'), indicator).map(({ label, value }, i) => ({
      label,
      name: formatLegendTranslations[label].label,
      unit: 'EUR',
      value: value,
      color:
        typeof formatLegendTranslations[label] === 'undefined'
          ? theme.color(`chart.${i + 1}`)
          : formatLegendTranslations[label].color,
    }));

  const supportHistoryFormat = data =>
    data.map(({ label, totalBenefits, totalCharges }) => ({
      label,
      Baten: totalBenefits,
      Lasten: totalCharges,
    }));

  const supportBudgetBenefits = filter(
    data =>
      [
        'governmentGrants',
        'otherGovernmentGrants',
        'tutionFeeCharges',
        'thirdPartyBenefits',
        'otherBenefits',
      ].includes(data.label),
    removedEmptyValuesSupportBudget
  );

  const data = {
    supportBudget: formatSupportBudget(partnership),
    reservePosition: formatReservePosition(_partnership, sector),
    equityIndicator: formatEquity(_partnership, prognosisData),
  };

  const chartState = {
    setSelectedView,
    selectedViews,
    selectIndicator,
  };

  return (
    <PartnershipLayout
      partnership={_partnership}
      seoMetaTags={copy.seoMetaTags}
      sector={sector}
      sideNavOptions={prepend(introductionOptions, sideNavOptions)}
      selectedIndicator={selectedIndicator}
      {...pageContext}
    >
      <Section id="introductie">
        <ImageInfoBlock node={<Img fluid={infoImage.childImageSharp.fluid} />}>
          <InView
            threshold={1}
            onChange={inView => {
              if (inView) {
                selectIndicator(introductionOptions.name);
              }
            }}
          >
            <Heading component="h1" size="xxl" weight="bold">
              {infoBlockTitle}
            </Heading>
          </InView>
          <Text size="m">{infoBlockBody}</Text>
        </ImageInfoBlock>
      </Section>
      {heading && (lead || intro) && (
        <Section>
          <Introduction
            component="h1"
            title={heading}
            text={lead && <>{parseTooltip(lead)}</>}
          />
          {intro && (
            <IntroText size="m" appearance="base">
              {parseTooltip(intro)}
            </IntroText>
          )}
        </Section>
      )}

      {/** Budget */}
      <ChartWrapper
        indicator={indicators[0]}
        sideNavOption={sideNavOptions[0]}
        chartState={chartState}
        heading={parseTooltip(copy.supportBudgetTitle)}
        text={parseTooltip(copy.supportBudgetBody)}
        chartTitle={'Saldo Baten en Lasten 2020:'}
        chartHistoryTitle={'Historische Baten en Lasten:'}
        chartTitleResult={totalDifference}
        chartSource={formatDataSource(dataSources.supportBudget)}
        explanationTitle="Toelichting"
        explanationText={_partnership.supportBudgetExplanation}
        hasHistoryChart
        historyChildren={
          <LineChart
            showTitle={isMobile()}
            data={supportHistoryFormat(data['supportBudget'])}
            height={indicators[0].height}
            xKey="label"
            unit="EUR"
            lineType="monotone"
            {...indicators[0].chartProps}
          />
        }
        historyChartSource={formatDataSource(dataSources.supportBudget)}
        historyChartLegend={[
          { label: 'Baten', color: theme.color('chart.1') },
          { label: 'Lasten', color: theme.color('chart.2') },
        ]}
      >
        <ComboChart alignment="flex-start">
          <GraphWrapper>
            <GraphHeading size="l">
              Baten
              <br />
              {totalBenefits}
            </GraphHeading>
            <DonutChart
              data={colorFormat(supportBudgetBenefits)}
              showSubLabels={false}
              disableLabels
              interactive
              activeSlice={currentBaten}
            />
            <Legend
              source={''}
              data={formatLegendItems(supportBudgetBenefits)}
              alignment="vertical"
              onItemHover={e => setCurrentBaten(e)}
            />
          </GraphWrapper>
          <GraphWrapper>
            <GraphHeading size="l">
              Lasten
              <br />
              {totalCharges}
            </GraphHeading>
            <DonutChart
              data={colorFormat(supportBudgetCharges)}
              showSubLabels={false}
              disableLabels
              interactive
              activeSlice={currentLasten}
            />
            <Legend
              source={''}
              data={formatLegendItems(supportBudgetCharges)}
              alignment="vertical"
              onItemHover={e => setCurrentLasten(e)}
            />
          </GraphWrapper>
        </ComboChart>
      </ChartWrapper>

      {/** Eigen vermogen */}
      <ChartWrapper
        indicator={indicators[1]}
        sideNavOption={sideNavOptions[1]}
        chartState={chartState}
        heading={parseTooltip(copy.equityTitle)}
        text={parseTooltip(copy.equityBody)}
        chartTitle={'Eigen vermogen'}
        chartSource={formatDataSource(dataSources.equity)}
        chartLegend={[
          { label: 'Eigen vermogen', color: theme.color('chart.1') },
          {
            label: 'Signaleringswaarde',
            color: theme.color('chart.3'),
          },
          {
            label: 'Eigen vermogen voorspelling',
            color: theme.color('chart.1'),
            stroked: true,
          },
          {
            label: 'Signaleringswaarde voorspelling',
            color: theme.color('chart.3'),
            stroked: true,
          },
        ]}
        chartLegendType={'circle'}
        explanationTitle="Toelichting"
        explanationText={_partnership.equityExplanation}
      >
        <PredictionLineChart
          data={data.equityIndicator}
          height={400}
          xKey="label"
          unit={'EUR'}
        />
      </ChartWrapper>

      {/** Reservepositie */}
      <ChartWrapper
        indicator={indicators[2]}
        sideNavOption={sideNavOptions[2]}
        chartState={chartState}
        heading={parseTooltip(copy.reservePositionTitle)}
        text={parseTooltip(copy.reservePositionBody)}
        chartTitle={'Reservepositie'}
        chartHistoryTitle={'Historische reservepositie'}
        chartSource={formatDataSource(dataSources.reservePosition)}
        hasHistoryChart
        historyChildren={
          <LineChart
            showTitle={isMobile()}
            data={map(
              year => ({
                ...reduce(
                  (acc, curr) => {
                    if (notNil(curr)) {
                      acc[curr[indicators[0].xKey]] = curr.values[0].value;
                    }
                    return acc;
                  },
                  {},
                  data['reservePosition'][year]
                ),
                [indicators[0].xKey]: year,
              }),

              Object.keys(data['reservePosition']).filter(year => {
                data['reservePosition'][year].reduce((acc, curr) => {
                  if (isNil(curr) || acc === false) {
                    return false;
                  }
                  return acc;
                }, true);
                return data;
              })
            )}
            height={indicators[1].height}
            xKey="label"
            unit="percentage"
          />
        }
        historyChartSource={formatDataSource(dataSources.reservePosition)}
        explanationTitle="Toelichting"
        explanationText={_partnership.reservePositionExplanation}
      >
        <Formula
          operator="division"
          result={{
            unit: 'percentage',
            value: formatCalculatedResistance(_partnership),
          }}
          values={[
            {
              description: 'Eigen vermogen – Materiële vaste activa',
              tooltip: (
                <>
                  <p>
                    Eigen vermogen:&nbsp;
                    <br />
                    <Strong>
                      {formatValue(getEquity(_partnership), 'EUR')}
                    </Strong>
                  </p>
                  <p>
                    Materiële vaste activa:&nbsp;
                    <br />
                    <Strong>
                      {formatValue(getMaterialFixedAssets(_partnership), 'EUR')}
                    </Strong>
                  </p>
                </>
              ),
              unit: 'EUR',
              value: calculatedEquity,
            },
            {
              description: 'Rijksbijdrage (baten)',
              unit: 'EUR',
              value: governmentGrants,
            },
          ]}
        />
      </ChartWrapper>

      {/** Signaleringswaarde */}
      <ChartWrapper
        indicator={indicators[3]}
        sideNavOption={sideNavOptions[3]}
        chartState={chartState}
        heading={parseTooltip(copy.signalingValueTitle)}
        text={parseTooltip(copy.signalingValueBody)}
        chartTitle={'Signaleringswaarde'}
        chartSource={formatDataSource(dataSources.signalingValue)}
        explanationTitle="Toelichting"
        explanationText={_partnership.signalingValueExplanation}
      >
        <Formula
          operator="subtraction"
          result={{
            unit: 'EUR',
            value: Math.round(getExcessiveReserve(_partnership)),
          }}
          values={[
            {
              description: 'Eigen vermogen',
              unit: 'EUR',
              value: Math.round(getPublicEquity(_partnership)),
            },
            {
              description: 'Signaleringswaarde',
              tooltip: (
                <>
                  <p>
                    <Strong>3,5%</Strong> van{' '}
                    <Strong>
                      {formatValue(getNetGain(_partnership), 'EUR')}
                    </Strong>
                  </p>
                  <br />
                  <p>
                    3,5% van het totale baten
                    <br /> met een minimum van <br />
                    {formatValue('250000', 'EUR')}.
                  </p>
                </>
              ),
              unit: 'EUR',
              value: Math.round(getSignalValue(_partnership)),
            },
          ]}
        />
      </ChartWrapper>

      {/** Vereveningen */}
      <ChartWrapper
        indicator={indicators[4]}
        sideNavOption={sideNavOptions[4]}
        chartState={chartState}
        heading={parseTooltip(copy.equalizationTitle)}
        text={parseTooltip(copy.equalizationBody)}
        chartTitle={'Vereveningen'}
        chartSource={formatDataSource(dataSources.equalizationSource)}
        chartLegend={Object.keys(equalizationColorMap).map(label => ({
          label,
          color: equalizationColorMap[label],
        }))}
        chartLegendStyle={'column'}
        explanationTitle="Toelichting"
        explanationText={_partnership.equalizationExplanation}
      >
        <>
          {mapJson !== null ? (
            <InteractiveMap
              sector={sector}
              active={equalization}
              mapJson={mapJson}
              tooltipKeys={['formattedAmountPerStudent']}
              focusOnLocation={_partnership.externalId}
              partnerships={equalization}
              onItemClick={f => f}
              interactivity={false}
              style={{ height: '470px', zIndex: 5 }}
            />
          ) : (
            <Loading style={{ height: '470px', zIndex: 5 }} />
          )}
        </>
      </ChartWrapper>

      <ThemeFooter
        sector={sector}
        slug={_partnership.externalId}
        next={{
          label: 'Volgende hoofdstuk: Organisatie',
          to: '/organisatie',
        }}
        prev={{
          label: 'Vorige hoofdstuk: Leerlingen',
          to: '/leerlingen',
        }}
      />
    </PartnershipLayout>
  );
};

Financial.propTypes = {
  pageContext: PropTypes.shape({
    dato: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string.isRequired,
      startIntroText: PropTypes.string,
      prognoses: PropTypes.array,
    }).isRequired,
    partnership: PropTypes.shape({
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      website: PropTypes.string.isRequired,
      indicators: PropTypes.shape({
        supportBudget: PropTypes.shape({
          _2019: PropTypes.object,
          _2020: PropTypes.object,
        }).isRequired,
      }),

      reservePosition: PropTypes.shape({
        _2019: PropTypes.shape({
          calculatedResistance: PropTypes.number.isRequired,
          equity: PropTypes.number.isRequired,
          governmentGrant: PropTypes.number.isRequired,
          materialFixedAssets: PropTypes.number.isRequired,
          resistance: PropTypes.number.isRequired,
        }).isRequired,
      }),
    }).isRequired,
    copy: PropTypes.shape({
      infoBlockTitle: PropTypes.string.isRequired,
      infoBlockBody: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      lead: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      supportBudgetTitle: PropTypes.string,
      supportBudgetBody: PropTypes.string,
      supportBudgetExplanation: PropTypes.string,
      reservePositionTitle: PropTypes.string,
      reservePositionBody: PropTypes.string,
      reservePositionExplanation: PropTypes.string,
      signalingValueTitle: PropTypes.string,
      signalingValueBody: PropTypes.string,
      signalingValueExplanation: PropTypes.string,
      equalizationTitle: PropTypes.string,
      equalizationBody: PropTypes.string,
      equalizationExplanation: PropTypes.string,
      equityTitle: PropTypes.string,
      equityBody: PropTypes.string,
      organizationFormExplanation: PropTypes.string,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
    sector: PropTypes.string.isRequired,
  }).isRequired,
};

export default Financial;
